
import Ads from '../svelte/ads/index.svelte';

const ads = document.getElementsByClassName('wc-ads');

const adsData = {
  binance: {
    type: 'standard',
  },
  threewin: {
    type: 'threewin',
  },
  leyton: {
    type: 'leyton',
  },
  hollisterco: {
    type: 'hollisterco',
  },
  weedclub: {
    type: 'weedclub',
  },
  weedclubAdvertise: {
    type: 'weedclub',
    text: 'Advertise'
  }
};

const adsList = ['binance', 'threewin', 'leyton', 'weedclub', 'hollisterco'];

if (ads) {
  //const adsList = Object.keys(rotation);
  let showAd = Math.floor(Math.random() * adsList.length)

  for (var i = 0; i < ads.length; i++) {
    let currentAd;
    let adName = ads[i].getAttribute('data-ad');
    if (adName) {
      currentAd = adsData[adName];
    } else {
      currentAd = adsData[adsList[showAd]];
    }



    let app = new Ads({
      target: ads[i],
      props: {
        config: currentAd,
        target: ads[i]
        // there may be a better way to access target,
        // yet to find so we will pass in
      },
    });

    if (showAd === adsList.length-1) {
      showAd = 0;
    } else {
      showAd++;
    }
  }
}
