<script>

  import { Modal } from "bootstrap.native";
  import { postData, formParams } from '../ajaxHelpers';

  //export let config = {};

  // this variable is set in DefinePlugin from webpack
  let recaptchaKey = RECAPTCHA_PUBLIC_KEY;

  const modalId = 'bannerModal' + Math.floor((Math.random() * 1000) + 1);
  let modal;

  function handleClick(event) {
    getModal().toggle();
	}

  function handleSubmit(event) {
    let message;
    postData(
      '/contact/ad_lead.json',
      formParams(this),
      {
        success: r =>  {
          alertSuccess('Success', r.success)
          this.reset();
          getModal().hide();
        },
        error: e => {
          alertError('Error', e.error)
        }
      },
      formParams(this)
    )
  }

  function getModal () {
    if (!modal) {
      let modalElement = document.getElementById(modalId);
      modal = new Modal(modalElement);
    }
    return(modal)
  }

</script>
<style>
  .banner {
    width: 728px;
    cursor: pointer;
  }
  .btn-modal-wide { width: 100%; }
  input {
   text-align:center;
  }
  .g-recaptcha {
    display: inline-block;
  }
</style>

<div class="col-lg-12 text-center">
  <script src='https://www.google.com/recaptcha/api.js' async defer></script>

  <img on:click={handleClick}
    src="https://s3-us-west-2.amazonaws.com/img.weedclub.com/ads/3win/3win_320x50px.png"
    class="img-responsive  center-block banner" alt="Tap for Wholesale CCELL&copy; Cartridges Direct">
    <p class="text-center">
      <a href="#" on:click={handleClick}>Tap for Wholesale CCELL&copy; Cartridges Direct</a>
    </p>
</div>

<div class="modal fade" tabindex="-1" role="dialog" id="{modalId}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">Group Buying for Wholesale CCELL&copy; Cartridges Direct</h4>
      </div>
      <form on:submit|preventDefault={handleSubmit}>
        <input type='hidden' name="body[description]" value="3Win Product Lead">
        <div class="modal-body">
          <div class="form-group">
            <img src="https://s3-us-west-2.amazonaws.com/img.weedclub.com/ads/3win/3win_320x50px.png"
              alt="3Win Banner"
              class="img-responsive">
          </div>
          <div class="form-group text-center">
            <label for="message">Company Name</label>
            <input type='text' name="body[company]" class='form-control', placeholder='Company Name' >
          </div>
          <div class="form-group text-center">
            <label for="message">Contact Name</label>
            <input type='text' name="contact[name]" class='form-control', placeholder='Contact Name' >
          </div>
          <div class="form-group text-center">
            <label for="message">Phone Number</label>
            <input type='tel' name="body[phone]"class='form-control', placeholder='(555) 555-5555' >
          </div>
          <div class="form-group text-center">
            <label for="message">Email</label>
            <input type='email' name="contact[email]" class='form-control', placeholder='email@example.com' >
          </div>
          <p class="text-center">
            Special Referral  Program For WeedClub&copy; Friends in High Places&trade;
          </p>
        </div>
        <div class="modal-footer">
          <div class="form-group submit text-center">
  					<div class="g-recaptcha text-center" data-sitekey="{recaptchaKey}"></div>
            <div><button type="submit" class="btn btn-default btn-modal-wide">Submit</button></div>
          </div>
        </div>
      </form>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
