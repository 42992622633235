<script>

  export let config = {};
  export let target = {};

  import Standard from './standard.svelte';
  import ThreeWin from './3win.svelte';
  import Leyton from './leyton.svelte';
  import WeedClub from './weedclub.svelte';
  import HollisterCo from './hollisterco.svelte';

  const bannerTypes = {
    standard: Standard,
    threewin: ThreeWin,
    leyton: Leyton,
    weedclub: WeedClub,
    hollisterco: HollisterCo
  }

  const bannerComponent = bannerTypes[config['type']];

</script>


<svelte:component this={bannerComponent} config={config}/>
