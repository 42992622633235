



export function postData (url, data, callbacks) {
  fetch(url, {
      method: 'POST',
      headers: {
        "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content
      },
      body: data
    }).then(response => {
      if (response.status === 200) {
        response.json().then(callbacks.success)
      } else if (response.status === 422) {
        response.json().then(callbacks.error)
      } else {
        throw new Error("Server error");
      }
    }).catch(error => console.log(error));
}

export function fetchData (url, successCallback) {
  fetch(url).then(response => {
      if (response.status === 200) {
        return(response.json());
      } else {
        throw new Error("Server error");
      }
    }).then(successCallback).catch(error => console.log(error));
}

export function formParams (form) {
  var params = new URLSearchParams();
  for (let i = 0; i < form.length; i++) {
    params.append(form[i].name, form[i].value)
  }
  return(params)
}
